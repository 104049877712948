import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import {
  NextIcon,
  PreviousIcon,
  PreviewIcon,
  UserOutlineIcon,
} from '../../assets/designer-icons';
import { LOGINMICROAPP } from '../../constants';
import SubHeader from '../../common/components/SubHeader';
import useStyles from './style';
import LeftPanelLayout from './components/leftpanel/index';
import RightPanelLayout from './components/rightpanel/index';
import TestPanelLayout from './components/testpanel/index';
import GroupCircleIcon from '../../assets/integration-builder/group-circle.png';
import CanvasLayout from './components/canvaspanel/index';
import OpenInNewTab from '../../assets/integration-builder/open-in-new-tab.svg';
import { ArrowBack, Settings } from '@material-ui/icons';
import {
  BsArrowsAngleExpand,
  BsArrowsAngleContract,
  BsFiles,
  BsFillVolumeUpFill,
  BsFillVolumeMuteFill,
} from 'react-icons/bs';
import MatchFields from './components/matchfields';
import MatchFieldsType2 from './components/matchfields/matchfieldstype2';
import AlertDialog from './components/matchfields/alertdialog';
import Dropdown from '../../common/components/SearchDropdown';
import MultiSelectDropdown from './components/dropdown-popper/multi-select';
import IBSpinner from './components/spinner';
import SapDropdown from './components/dropdown';
import ContextMenu from './components/contextmenu';
import VariablePanel from './components/variablepanel';
import CustomContextMenu from './components/contextmenu/custom-menu';
import BlocksDropdown from './components/dropdown/blocksdropdown';
import * as helpers from '../../helpers/integrationbuilder.js';
import { BaseURL } from './resources/resources/js/message';
import DropdownPopper from './components/dropdown-popper';
import { getPermissions } from '../../utils/common';
import IfElseSettingsDropdown from './components/dropdown-popper/ifelse-settings';
import LockNotification from '../../common/components/LockNotification';
import { configMessage } from '../../common/messages/config';

const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: 'Home', url: './', selected: true });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: 'Database', url: './Database', selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: 'Drive', url: './Drive', selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: 'Settings', url: './Settings', selected: false });
  }

  return arr;
};

export default function (props) {
  const classes = useStyles();
  let bfId = props.match.params?.ProcessId;
  const [showTestpanel, setShowTestPanel] = useState(false);
  const [showVariablePanel, setShowVariablePanel] = useState('');
  // const [variables, setVariables] = useState([]);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isLinkedToProcess, setIsLinkedToProcess] = useState(false);
  const {
    variables = [], setVariables = () => null,
    isHeaderButtonEnabled = {}
  } = props;

  useEffect(() => {
    if (!!window.application && !!window.application.audio) {
      setIsMuted(window.application.audio.muted);
    }
    if (!!props.history.location && !!props.history.location.state) {
      const { linkedProcess } = props.history.location.state;
      setIsLinkedToProcess(linkedProcess);
    }
    getVariablesData();
  }, []);

  useEffect(() => {
    let prjName = window?.application?.projectName || "";
    let bfName = window?.application?.businessFunctionName || "";
    let ibName = window?.application?.integrationBuilderName || "";
    if(ibName && window.name){
      let title = `${ibName} - ${bfName} - ${prjName}`
      document.title = `${title} | ${configMessage.T5028}`;
    }else {
      document.title = configMessage.T5028;
    }

    return () => {
      document.title = configMessage.T5028;
    }
  }, [window?.application?.integrationBuilderName])

  const toggleTestPanel = () => {
    setShowTestPanel(true);
    props.handleCanvasClick();
  };

  const middleTab = (
    <Grid xs={2} className={`subHeaderGrid ${classes.blockBtnGrid}`}></Grid>
  );

  const handleUndo = () => {
    if (window.application.commands.undoStack.length > 0) {
      window.application.commands.undo();
      window.application.refresh();
      window.application.save();
    }
  };

  const handleRedo = () => {
    if (window.application.commands.redoStack.length > 0) {
      window.application.commands.redo();
      window.application.refresh();
      window.application.save();
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      document.getElementsByClassName('header')[0].style.display = 'none';
      document.getElementsByClassName('tab-container')[0].style.paddingTop =
        '0px';
      document.getElementsByClassName(
        'integration-builder-panel'
      )[0].style.top = '50px';
      document.getElementsByClassName('sub-header')[0].style.top = '0px';
      setShowFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setShowFullScreen(false);
        document.getElementsByClassName('header')[0].style.display = '';
        document.getElementsByClassName('tab-container')[0].style.paddingTop =
          '64px';
        document.getElementsByClassName(
          'integration-builder-panel'
        )[0].style.top = '113px';
        document.getElementsByClassName('sub-header')[0].style.top = '64px';
      }
    }
    props.handleCanvasClick();
  };

  const canUpdate = () => {
    return (
      getPermissions()?.projects?.business_function?.integration_builder?.canUpdate &&
      getPermissions()?.projects?.business_function?.integration_builder?.canCreate &&
      getPermissions()?.projects?.business_function?.integration_builder?.canDelete
    );
  };

  const canTest = () => {
    return (
      getPermissions()?.projects?.business_function?.integration_builder?.canView && canUpdate()
    );
  };

  const openInNewWindow = () => {
    let url = `${window.location.origin}${window.location.pathname}`;
    props.history.go(-1);
    window.open(url, window.application.integrationBuilderName);
  };

  const variableCallback = (res) => {
    let bfvariables = JSON.parse(res.data.object).variable;
    setVariables(bfvariables);
  };

  const getVariablesData = (e, callback = () => null) => {
    let _urlKey =
      BaseURL.configurator +
      'variables/bf/' +
      window.application.businessFunctionName;
    let _qryString = 'project_name=' + window.application.projectName;
    helpers.getData(_urlKey, _qryString, variableCallback, e);
    callback();
  };

  const toggleVariableDropdown = (e, text = '') => {
    setShowVariablePanel(text);
    if (text) {
      getVariablesData(e);
    }
  };

  const addVariables = (parameters, type, isEdit, callback = () => null) => {
    let bfName = window.application.businessFunctionName;
    let prjName = window.application.projectName;
    let v = [...variables, parameters];
    helpers.saveVariables(v, prjName, bfName, (e) => getVariablesData(e, callback), {});
  };

  const rightTab = (
    <Grid
      xs={5}
      container
      className='subHeaderGrid'
      justify='flex-end'
      alignItems='center'
    >
      <ButtonGroup color='light'>
        {/* <Button className={`${classes.groupCircleBtn}`}>
                    <img src={GroupCircleIcon} />
                </Button> */}
        { 
          canUpdate() &&
          <Button
            className={`${classes.buttonBorder} option leftBorder`}
            title='undo'
            onClick={handleUndo}
            disabled={!isHeaderButtonEnabled.undo}
          >
            <PreviousIcon className='gearIcon undoIcon' />
          </Button>
        }
        { 
          canUpdate() &&
          <Button
            className={`${classes.buttonBorder} option rightBorder`}
            title='redo'
            onClick={handleRedo}
            disabled={!isHeaderButtonEnabled.redo}
          >
            <NextIcon className='gearIcon redoIcon' />
          </Button>
        }
        <Button
          className={`${classes.fullScreenBtn}`}
          title={showFullScreen ? configMessage.T4723 : configMessage.T4724}
          onClick={toggleFullScreen}
          disabled = {!isHeaderButtonEnabled.full_screen}
        >
          {showFullScreen ? (
            <BsArrowsAngleContract title={configMessage.T4724} />
          ) : (
            <BsArrowsAngleExpand title={configMessage.T4723} />
          )}
        </Button>
        {window.name === '' && (
          <Button
            className={`${classes.undocBtn}`}
            title={configMessage.T4722}
            onClick={openInNewWindow}
            disabled = { !isHeaderButtonEnabled.undoc }
          >
            <img src={OpenInNewTab} />
          </Button>
        )}
        {/*<Button
          className={`${classes.volumeBtn}`}
          title={!!isMuted ? "Unmute" : "Mute"}
          onClick={() => {
            window.application.audio.muted = !isMuted;
            setIsMuted(!isMuted);
          }}
        >
          {!!isMuted ? (
            <BsFillVolumeMuteFill title="Mute" />
            ) : (
            <BsFillVolumeUpFill title="Unmute" />
          )}
        </Button>*/}
        { 
          canTest() &&
          <Button
            className={`${classes.buttonBorder} option testBorder`}
            onClick={toggleTestPanel}
            disabled={!isHeaderButtonEnabled.test}
          >
            {<PreviewIcon className='gearIcon' />}
            <span>Test</span>
          </Button>
        }
      </ButtonGroup>
    </Grid>
  );

  const openBusinessFunction = () => {
    let projName = props.match.params.id;
    let bfName = props.match.params.ProcessId;
    props.history.push(`/Project/${projName}/BusinessFunction/${bfName}`);
  };

  const breadcrumbPath = (
    <Grid
      xs={12}
      container
      className='subHeadGrid'
      justify='flex-start'
      alignItems='center'
    >
      <Grid xs={9}>
        <Box className='flexRow canvas-breadcrumb'>
          <ArrowBack
            className='arrow-back-icon'
            onClick={() => props.history.goBack()}
          />
          <Breadcrumbs>
            {!!isLinkedToProcess && bfId && (
              <Link
                color='inherit'
                className='flexRow label'
                onClick={() =>
                  props.history.push(
                    `/Project/${props.match.params.id}/BusinessProcess/${props.match.params.id}`
                  )
                }
              >
                <Settings className='user-icon' />
                {configMessage.T4767}
              </Link>
            )}
            { !bfId && 
            <Link
              color='inherit'
              className='flexRow label'
              onClick={() => props.history.push('/Library/Services')}
            >
              {'Services'}
            </Link>
            }
            { bfId && 
            <Link
              color='inherit'
              className='flexRow label'
              onClick={openBusinessFunction}
            >
              {!isLinkedToProcess && <Settings className='user-icon' />}
              {props.match.params?.ProcessId}
            </Link>
            }
            {props.match.params?.TaskName && (
              <Link
                color='inherit'
                className='label'
                onClick={() => props.history.goBack()}
              >
                {props.match.params?.TaskName}
              </Link>
            )}
            {props.match.params?.TimerName && (
              <Link
                color='inherit'
                className='label'
                onClick={() => props.history.goBack()}
              >
                {props.match.params?.TimerName}
              </Link>
            )}
            <div color='textPrimary' className='label no-link' aria-current='page'>
              {window.application?.integrationBuilderName || ""}
            </div>
          </Breadcrumbs>
        </Box>
      </Grid>
    </Grid>
  );

  const dropDownopen = Boolean(props.anchorEl);
  const dropdownId = dropDownopen ? 'spring-popper' : undefined;

  // const handleToggeFields = (event) => {
  // let obj = {
  //     setTable: 'table1',
  //     toTable: 'table2',
  //     setCol: [{ 'name': 'samp1', 'XPath': 'BOS$output$samp1' },
  //     { 'name': 'samp2', 'XPath': 'BOS$output$samp2' }],
  //     toCol: [],
  //     mappedObj: [{ 'name': 'mapsamp1', 'XPath': 'BOS$output$samp1' },
  //     { 'name': 'mapsamp2', 'XPath': 'BOS$output$samp2' }]
  // };
  // props.toggleMatchFields(obj, 'con');
  // props.toggleDropdown(event, dropDownTableValues, 'single', 'string');
  // props.toggleContextMenu(event)
  // const data1 = () => {
  //     console.log('data111===');
  // }
  // const data2 = () => {
  //     console.log('data22====');
  // }
  // const data = [
  //     {'option':'option1','event':data1},
  //     {'option':'option2','event':data2}
  // ]
  // props.toggleBlocksDropdown(event, 'itemClick','', 'Math')
  // }

  return (
    <Box
      component='div'
      className={`integration-builder-panel ${classes.integrationBuilderLayout}`}
    >
      <SubHeader
        menus={bfId && window?.application?.projectName !== LOGINMICROAPP ? subHeaderMenus() : []}
        middleTab={middleTab}
        rightTab={rightTab}
        navigateTab={props.navigateTab}
      />
      <Grid xs={12} className={classes.mainLayout}>
        {/* {
          canUpdate() && */}
          <Grid xs={3} className={classes.leftLayout}>
            <LeftPanelLayout
              leftpanel={props.leftPanel}
              searchBlocks={props.searchBlocks}
              handleCanvasClick={props.handleCanvasClick}
              isSearchData={props.isSearchData}
              canUpdate={canUpdate()}
            />
          </Grid>
        {/* } */}
        <Grid
          // xs={canUpdate() ?  7 : 10}
          xs={7}
          className={
            props.showDropdown || props.isScrolled
              ? classes.canvasLayout
              : `${classes.canvasLayout} ${classes.enableCanvasScroll}`
          }
        >
          {breadcrumbPath}
          {/* <button onClick={handleToggeFields}>Click me</button> */}
          <CanvasLayout
            canvasEvent={props.canvasEvent}
            showDropdown={props.showDropdown}
            handleCanvasClick={props.handleCanvasClick}
            isScrolled={props.isScrolled}
            handleCanvasScroll={props.handleCanvasScroll}
          />
        </Grid>
        <Grid>
          <RightPanelLayout
            bfVariables={variables}
            rightPanel={props.rightPanel}
            addNewParameters={props.addNewParameters}
            fetchVariableData={props.fetchVariableData}
            filteredVairableData={props.filteredVairableData}
            builderId={props.match.params?.builderId}
            handleCanvasClick={props.handleCanvasClick}
            handleDeleteVariable={props.handleDeleteVariable}
            toggleVariableDropdown={toggleVariableDropdown}
            setShowVariablePanel={setShowVariablePanel}
            setVariablesList={setVariables}
            canUpdate={canUpdate()}
            baasDetail = {
              window.application != undefined
                ? window.application 
                : {businessFunctionName: null}
            }
            updateVariableParameters={props.updateVariableParameters}
          />
        </Grid>
      </Grid>
      {showVariablePanel && (
        <VariablePanel
          show={showVariablePanel}
          close={() => setShowVariablePanel('')}
          rightPanel={props.rightPanel}
          addNewParameters={addVariables}
          handleCanvasClick={props.handleCanvasClick}
          toggleVariableDropdown={toggleVariableDropdown}
          variables={variables}
        />
      )}
      {showTestpanel && (
        <TestPanelLayout
          show={showTestpanel}
          close={() => {
            setShowTestPanel(false);
            props.clearOutputXmlData();
          }}
          testValues={props.rightPanel}
          system={props.system}
          generateTest={props.generateTest}
          outputXmlData={props.outputXmlData}
        />
      )}
      {props.showMatchFields && (
        <MatchFields
          show={props.leftFields.length > 0 ? props.showMatchFields : false}
          close={(e) => props.toggleMatchFields('', 'closetype1')}
          leftFields={props.leftFields}
          rightFields={props.rightFields}
          matchFields={props.matchFields}
          toggleMatchFields={props.toggleMatchFields}
          allRightFieldValues={props.allRightFieldValues}
          tableMappingType={props.tableMappingType}
          tableNames={props.tableNames}
          mapType={props.mapType}
        />
      )}
      {props.showMatchFieldsType2 && !props.rightFields?.length > 0 && (
        <MatchFieldsType2
          show={(e) => props.toggleMatchFields('', 'opendialog')}
          close={(e) => props.toggleMatchFields('', 'closedialog')}
          leftFields={props.leftFields}
          rightFields={props.rightFields}
          alertShow={(e) => props.toggleMatchFields('', 'openalert')}
          alertClose={(e) => props.toggleMatchFields('', 'closealert')}
          alertYes={(e) => props.toggleMatchFields('', 'alertYes')}
          showAlertDialog={props.showAlertDialog}
          showMatchFieldsType2={props.showMatchFieldsType2}
          matchFields={props.matchFields}
          toggleMatchFields={props.toggleMatchFields}
          copyTable1Values={props.copyTable1Values}
          tableNames={props.tableNames}
        />
      )}
      {/* {props.showDropdown && !props.dropdownBlockType && (
        <Dropdown
          dropDownValues={props.dropDownValues}
          selectDropDownValue={props.selectDropDownValue}
          dropDownTableValues={props.dropDownTableValues}
          handleDropDownClose={props.handleDropDownClose}
          anchorEl={props.anchorEl}
          dropDownopen={dropDownopen}
          dropdownId={dropdownId}
          dropDownSelectType={props.dropDownSelectType}
          dropDownVariableType={props.dropDownVariableType}
          dropdownPosition={props.dropdownPosition}
          showDropdown={props.showDropdown}
          dropdowntype="IBDropdown"
          selectedDropdownValues={props.selectedDropdownValues}
          dropdownBlockType={props.dropdownBlockType}
          handleDropdownSearch={props.handleDropdownSearch}
        />
      )}
      {props.showDropdown && props.dropdownBlockType === "SAPModuleList" && (
        <SapDropdown
          dropDownValues={props.dropDownValues}
          selectDropDownValue={props.selectDropDownValue}
          dropDownTableValues={props.dropDownTableValues}
          handleDropDownClose={props.handleDropDownClose}
          dropDownopen={dropDownopen}
          dropdownId={dropdownId}
          dropDownSelectType={props.dropDownSelectType}
          dropDownVariableType={props.dropDownVariableType}
          dropdownPosition={props.dropdownPosition}
          showDropdown={props.showDropdown}
          selectedDropdownValues={props.selectedDropdownValues}
          dropdownBlockType={props.dropdownBlockType}
          handleDropdownSearch={props.handleDropdownSearch}
          sapDropdownValues={props.sapDropdownValues}
          sapDropdownData={props.sapDropdownData}
        />
      )} */}
      {props.showAlertDialog && (
        <AlertDialog
          alertYes={(e) => props.toggleMatchFields('', 'alertYes')}
          alertShow={(e) => props.toggleMatchFields('', 'openalert')}
          alertClose={(e) => props.toggleMatchFields('', 'closealert')}
          tableNames={props.tableNames}
        />
      )}
      {props.showContextMenu && (
        <ContextMenu
          conextMenuPosition={props.conextMenuPosition}
          conextMenuData={props.conextMenuData}
          handleContextMenuClick={props.handleContextMenuClick}
          currentContextMenuItem={props.currentContextMenuItem}
          handleCloseContextMenu={props.handleCloseContextMenu}
        />
      )}
      {props.showCustomContextMenu && (
        <CustomContextMenu
          conextMenuPosition={props.customConextMenuPosition}
          conextMenuData={props.customContextMenuData}
          handleContextMenuClick={props.handleCustomContextMenuClick}
          currentContextMenuItem={props.currentContextMenuItem}
          handleCloseContextMenu={props.handleCloseCustomContextMenu}
        />
      )}
      {props.showBlocksDropdown && (
        <BlocksDropdown
          blocksDropdown={props.blocksDropdown}
          blocksDropdownPosition={props.blocksDropdownPosition}
          handleBlocksDropdownClose={props.handleBlocksDropdownClose}
          selectedBlockDropdownValue={props.selectedBlockDropdownValue}
          handleSelectedBlockDropdownValue={
            props.handleSelectedBlockDropdownValue
          }
          blockDropdownType={props.blockDropdownType}
        />
      )}
      {props.loading && <IBSpinner loadingAxis={props.loadingAxis} />}
      {props.showDropdown && props.dropdownBlockType === 'SAPModuleList' && (
        <DropdownPopper
          selectDropDownValue={props.selectDropDownValue}
          handleDropdownSearch={props.handleDropdownSearch}
          handleDropDownClose={props.handleDropDownClose}
          dropDownValues={props.sapDropdownValues}
          sapDropdownData={props.sapDropdownData}
          dropdownPosition={props.dropdownPosition}
        />
      )}
      {props.showDropdown && !props.dropdownBlockType && (
        <MultiSelectDropdown
          dropDownValues={props.dropDownValues}
          selectDropDownValue={props.selectDropDownValue}
          dropDownTableValues={props.dropDownTableValues}
          handleDropDownClose={props.handleDropDownClose}
          anchorEl={props.anchorEl}
          dropDownopen={dropDownopen}
          dropdownId={dropdownId}
          dropDownSelectType={props.dropDownSelectType}
          dropDownVariableType={props.dropDownVariableType}
          dropdownPosition={props.dropdownPosition}
          showDropdown={props.showDropdown}
          dropdowntype='IBDropdown'
          selectedDropdownValues={props.selectedDropdownValues}
          dropdownBlockType={props.dropdownBlockType}
          handleDropdownSearch={props.handleDropdownSearch}
        />
      )}
      {props.showIfElseSettings.flag && (
        <IfElseSettingsDropdown
          dropdownPosition={props.dropdownPosition}
          details={props.showIfElseSettings.details}
          handleClick={props.toggleIfElseSettings}
          maxLimit={10}
          minLimit={1}
        />
      )}
      {
        getPermissions()?.projects?.business_function?.integration_builder &&
        getPermissions()?.projects?.business_function?.integration_builder?.canView &&
        !canUpdate() &&
        <LockNotification
          show={true}
        />
      }
    </Box>
  );
}
