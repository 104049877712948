import { clearAuth } from '../auth/user'
import { forceLogout } from '../common';

export function getUrl() {
    const { hostname, protocol } = window.location;
    
    if (hostname === "localhost" || hostname.includes("dev-qm4"))
        return "https://dft6.bifreedom.com/";
    else
        return protocol + "//" + hostname;
}

let authorisedHeader = {
    "accept": "application/json",
};

export function setToken(token) {
    if (token) {
        authorisedHeader.authorization = `Bearer ${token}`;
    } else {
        let accessToken =  getCookie('accessToken');
        if(accessToken)
            authorisedHeader.authorization = `Bearer ${accessToken}`;
        else
            delete authorisedHeader.authorization;
    }
}
function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

export async function deleteAPI(url, payload) {
    let options = {
        headers: { 
            ...authorisedHeader,
            "X-PLR-User-Agent":navigator.userAgent,
        },
        method: "DELETE",
    }
    if (payload)
        options.body = typeof payload === "string" ? payload : JSON.stringify(payload);
    try {
        const result = await fetch(url, options);
        if (result.status >= 200 && result.status < 300) {
            let res = await result.json();
            if (res.status.type === 'S' || (res.status==='Success' && res.error===false))
                return [null, res];
            else if(res.status === 'Error' && res.error===true)
                return [res.errmsg, null];
            else
                return [res.message, null];
        } else if (result.status === 401) {
            forceLogout();
            return ["Session Expired!", null];
        }
        else {
            return [`ERROR: ${result.status}`, null]
        }
    } catch (error) {
        return [`ERROR: ${error.message}`, null]
    }
}


export async function get(url, headers = null) {
    const h = headers || authorisedHeader;
    let options = {
        headers: { 
            ...(h || {}),
            "X-PLR-User-Agent":navigator.userAgent,
    },
        method: "GET",
    }
    try {
        const result = await fetch(url, options);
        if (result.status >= 200 && result.status < 300) {
            let res = await result.json();
            if (res.status.type === 'S' || res.error === false)
                return [null, res];
            else
                return [res.status.message, res];
        } else if (result.status === 401) {
            forceLogout();
            return ["Session Expired!", null];
        }
        else {
            return [`ERROR: ${result.status}`, null]
        }
    } catch (error) {
        return [`ERROR: ${error.message}`, null]
    }
}

export async function postLogin(url, payload, headers = null) {
    const h = headers || authorisedHeader;
    let options = {
        headers: {
            ...(h || {}),
            "accept": "application/json",
            "Content-Type": "application/json",
            "X-PLR-User-Agent":navigator.userAgent,
        },
        method: "POST"
    }

    if (payload)
        options.body = typeof payload === "string" ? payload : JSON.stringify(payload);

    try {
        const result = await fetch(url, options);
        if (result.status === 200 || result.status === 201) {
            let res = await result.json();
            if (res.status === 'S')
                return [null, res];
            else
                return [res.message, res];
        } else if (result.status === 401) {

        } else {
            return [`ERROR: ${result.status}`, null]
        }
    } catch (error) {
        return [`ERROR: ${error.message}`, null]
    }
}

export async function post(url, payload, headers = null) {
    const h = headers || authorisedHeader;
    let options = {
        headers: {
            ...(h || {}),
            "accept": "application/json",
            "Content-Type": "application/json",
            "X-PLR-User-Agent":navigator.userAgent,
        },
        method: "POST"
    }

    if (payload)
        options.body = typeof payload === "string" ? payload : JSON.stringify(payload);

    try {
        const result = await fetch(url, options);
        if (result.status >= 200 && result.status < 300) {
            let res = await result.json();
            if (res.status?.type === 'S')
                return [null, res];
            else if(res.code && res.code===200)
                return [null, res];
            else
                return [res.message, res];
        } else if (result.status === 401) {
            forceLogout();
            return ["Session Expired!", null];
        } else {
            return [`ERROR: ${result.status}`, null]
        }
    } catch (error) {
        return [`ERROR: ${error.message}`, null]
    }
}

export async function upload(url, payload) {
    const h = authorisedHeader;
    let options = {
        headers: {
            ...(h || {}),
            "accept": "application/json",
            "Content-Type": "application/json",
            "X-PLR-User-Agent":navigator.userAgent,
        },
        method: "POST"
    }

    if (payload)
        options.body = payload;

    try {
        const result = await fetch(url, options);
        let res = await result.json();
        if (result.status >= 200 && result.status < 300) {
            if (res.status.type === 'S')
                return [null, res];
            else
                return [res.message, res];
        } else if (result.status === 401) {
            forceLogout();
            return ["Session Expired!", null];
        } else {
            return [`ERROR: ${result.status}`, null]
        }
    } catch (error) {
        return [`ERROR: ${error.message}`, null]
    }
}

export async function put(url, payload, headers = null) {
    const h = headers || authorisedHeader;
    let options = {
        headers: {
            ...(h || {}),
            "accept": "application/json",
            "Content-Type": "application/json",
            "X-PLR-User-Agent":navigator.userAgent,
        },
        method: "PUT"
    }

    if (payload)
        options.body = typeof payload === "string" ? payload : JSON.stringify(payload)

    try {
        const result = await fetch(url, options);
        let res = await result.json();
        if (result.status >= 200 && result.status < 300) {
            if (res.status.type === 'S')
                return [null, res];
            else if(res.status.type === "E") 
                return [res.status.message, null];
            else
                return [res.message, res];
        } else if (result.status === 401) {
            forceLogout();
            return ["Session Expired!", null];
        } else {
            return [`ERROR: ${result.status}`, null]
        }
    } catch (error) {
        return [`ERROR: ${error.message}`, null]
    }
}

export function getEncodedPercent() {
    return encodeURIComponent('%');
}

export function getLikeValue(value) {
    return getEncodedPercent() + value + getEncodedPercent();
}

export function encodeFilter(input) {
    return encodeURIComponent("'%" + input + "%'");
}

export function getFilter(asset) {
    let filter = "&$filter=";
    let obj = [];
    Object.keys(asset).map(function (key, index) {
        if (asset[key])
            obj.push(key + ' LIKE ' + encodeFilter(asset[key]));
    });
    obj.length > 0 ? filter += obj.join(" AND ") : filter = "";
    return filter;
}


export async function download(url) {
    let options = {
        headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        method: "GET"
    }

    try {
        const result = await fetch(url, options);
        let res = await result.text();
        return !res.includes("Error") ? res : "";
    } catch (error) {
        return "";
    }
}

export function downloadFileFromUrl(value, name) {
    const a = document.createElement("a");
    a.href = value;
    a.download = name ? name : "";
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export async function postFile(url, payload, headers = null) {
  const h = headers || authorisedHeader;
  let options = {
    headers: {
      ...(h || {}),
      accept: "application/json",
      "X-PLR-User-Agent":navigator.userAgent,
    },
    method: "POST",
    body: payload,
  };

  try {
    const result = await fetch(url, options);
    if (result.status >= 200 && result.status < 300) {
      let res = await result.json();
      if (res.status?.type === "S") return [null, res];
      else if(res.status==='Success') return [null, res];
      else return [res.message, res];
    } else if (result.status === 401) {
      forceLogout();
      return ["Session Expired!", null];
    } else {
      return [`ERROR: ${result.status}`, null];
    }
  } catch (error) {
    return [`ERROR: ${error.message}`, null];
  }
}
