import React, { Component }  from 'react';
import  { ReactComponent as Runondevice } from '../../../../../assets/integration-builder/images/lanes/runondevice.svg';
import  RunondeviceLarge from '../../../../../assets/integration-builder/images/lanes/large/runondevice.svg';
import  { ReactComponent as Runonpremise } from '../../../../../assets/integration-builder/images/lanes/runonpremise.svg';
import  RunonpremiseLarge from '../../../../../assets/integration-builder/images/lanes/large/runonpremise.svg';
import  { ReactComponent as Runoncloud } from '../../../../../assets/integration-builder/images/lanes/runoncloud.svg';
import RunoncloudLarge from '../../../../../assets/integration-builder/images/lanes/large/runoncloud.svg';

import  { ReactComponent as Repeater } from '../../../../../assets/integration-builder/images/logic/repeater1.svg';
import  RepeaterLarge from '../../../../../assets/integration-builder/images/logic/large/repeater1.svg';
import  { ReactComponent as Loopuntil } from '../../../../../assets/integration-builder/images/logic/loopuntil.svg';
import  LoopuntilLarge from '../../../../../assets/integration-builder/images/logic/large/loopuntil.svg';
import  { ReactComponent as If } from '../../../../../assets/integration-builder/images/logic/if1.svg';
import  IfLarge from '../../../../../assets/integration-builder/images/logic/large/if1.svg';
import  { ReactComponent as Ifelse } from '../../../../../assets/integration-builder/images/logic/ifelse.svg';
import  IfelseLarge from '../../../../../assets/integration-builder/images/logic/large/ifelse.svg';
import  { ReactComponent as Comparison } from '../../../../../assets/integration-builder/images/logic/comparison.svg';
import  ComparisionLarge from '../../../../../assets/integration-builder/images/logic/comparison.svg';
import  { ReactComponent as And } from '../../../../../assets/integration-builder/images/logic/and.svg';
import  { ReactComponent as Break } from '../../../../../assets/integration-builder/images/logic/break.svg';
import  { ReactComponent as Terminate } from '../../../../../assets/integration-builder/images/logic/terminate.svg';
import  AndLarge from '../../../../../assets/integration-builder/images/logic/and.svg';
import  BreakLarge from '../../../../../assets/integration-builder/images/logic/break.svg';
import  TerminateLarge from '../../../../../assets/integration-builder/images/logic/terminate.svg';
import  { ReactComponent as Addrow } from '../../../../../assets/integration-builder/images/logic/addrow.svg';
import  AddrowLarge from '../../../../../assets/integration-builder/images/logic/large/addrow.svg';
import  { ReactComponent as LogicSet } from '../../../../../assets/integration-builder/images/logic/set.svg';
import  LogicSetLarge from '../../../../../assets/integration-builder/images/logic/set.svg';


import  { ReactComponent as Sort } from '../../../../../assets/integration-builder/images/utilities/sort.svg';
import  { ReactComponent as Downloadfile } from '../../../../../assets/integration-builder/images/utilities/downloadfile.svg';
import   {ReactComponent as GenerateQR} from '../../../../../assets/integration-builder/images/utilities/generateqr.svg';
import  { ReactComponent as Generatefile } from '../../../../../assets/integration-builder/images/utilities/generatefile.svg';
import  { ReactComponent as Math } from '../../../../../assets/integration-builder/images/utilities/math.svg';
import  { ReactComponent as Parsecsv } from '../../../../../assets/integration-builder/images/utilities/parsecsv.svg';
import  { ReactComponent as Comment } from '../../../../../assets/integration-builder/images/utilities/comment.svg';
import  SortLarge from '../../../../../assets/integration-builder/images/utilities/large/sort.svg';
import  DownloadfileLarge from '../../../../../assets/integration-builder/images/utilities/large/downloadfile.svg';
import  GenerateQRLarge from '../../../../../assets/integration-builder/images/utilities/large/generateqr.svg';
import  GeneratefileLarge from '../../../../../assets/integration-builder/images/utilities/large/generatefile.svg';
import  ParsecsvLarge from '../../../../../assets/integration-builder/images/utilities/large/parsecsv.svg';
import  MathLarge from '../../../../../assets/integration-builder/images/utilities/math.svg';
import  CommentLarge from '../../../../../assets/integration-builder/images/utilities/comment.svg';

import  { ReactComponent as Mailto } from '../../../../../assets/integration-builder/images/notifications/mailto.svg';
import  { ReactComponent as Pushuser } from '../../../../../assets/integration-builder/images/notifications/pushuser.svg';
import  { ReactComponent as Devicesyncuser } from '../../../../../assets/integration-builder/images/notifications/devicesyncuser.svg';

import MailtoLarge from '../../../../../assets/integration-builder/images/notifications/large/mailto.svg';
import  PushuserLarge from '../../../../../assets/integration-builder/images/notifications/large/pushuser.svg';
import  DevicesyncuserLarge from '../../../../../assets/integration-builder/images/notifications/large/devicesyncuser.svg';

import  { ReactComponent as Ftp } from '../../../../../assets/integration-builder/images/ftp/ftp.svg';
import  FtpLarge from '../../../../../assets/integration-builder/images/ftp/large/ftp.svg';


import  { ReactComponent as Bos } from '../../../../../assets/integration-builder/images/bos/bos.svg';
import  BosLarge from '../../../../../assets/integration-builder/images/bos/large/bos.svg';

import  { ReactComponent as Webservice } from '../../../../../assets/integration-builder/images/services/webservice.svg';
import  { ReactComponent as Restservice } from '../../../../../assets/integration-builder/images/services/restservice.svg';
import  WebserviceLarge from '../../../../../assets/integration-builder/images/services/large/webservice.svg';
import  RestserviceLarge from '../../../../../assets/integration-builder/images/services/large/restservice.svg';

import  { ReactComponent as SPRestservice } from '../../../../../assets/integration-builder/images/sharepoint/restservice.svg';
import  SPRestserviceLarge from '../../../../../assets/integration-builder/images/sharepoint/large/restservice.svg';

import  { ReactComponent as Createuser } from '../../../../../assets/integration-builder/images/businessuser/createuser.svg';
import  { ReactComponent as ResetPassword } from '../../../../../assets/integration-builder/images/businessuser/resetpwd.svg';
import  { ReactComponent as Updateuser } from '../../../../../assets/integration-builder/images/businessuser/updateuser.svg';
import  { ReactComponent as Addrole } from '../../../../../assets/integration-builder/images/businessuser/addrole.svg';
import  { ReactComponent as RoleList } from '../../../../../assets/integration-builder/images/businessuser/rolelist.svg';
import  { ReactComponent as Removerole } from '../../../../../assets/integration-builder/images/businessuser/removeGroup.svg';
import  { ReactComponent as Userlist } from '../../../../../assets/integration-builder/images/businessuser/userlist.svg';
import  { ReactComponent as Userdetails } from '../../../../../assets/integration-builder/images/businessuser/userdetails.svg';
import  { ReactComponent as UserProperty } from '../../../../../assets/integration-builder/images/businessuser/userProperty.svg';
import  { ReactComponent as PassswordPolicy } from '../../../../../assets/integration-builder/images/businessuser/pwdpolicy.svg';
import  {ReactComponent as LdapSearch} from '../../../../../assets/integration-builder/images/businessuser/ldapsearch.svg';
import  CreateuserLarge from '../../../../../assets/integration-builder/images/businessuser/large/createuser.svg';
import  ResetPasswordLarge from '../../../../../assets/integration-builder/images/businessuser/large/resetpwd.svg';
import  UpdateuserLarge from '../../../../../assets/integration-builder/images/businessuser/large/updateuser.svg';
import UserPropertyLarge from '../../../../../assets/integration-builder/images/businessuser/large/userproperty.svg'
import  AddroleLarge from '../../../../../assets/integration-builder/images/businessuser/large/addrole.svg';
import  RoleListLarge from '../../../../../assets/integration-builder/images/businessuser/large/rolelist.svg';
import  UserListLarge from '../../../../../assets/integration-builder/images/businessuser/large/userlist.svg';
import  UserDetailLarge from '../../../../../assets/integration-builder/images/businessuser/large/userdetails.svg';
import  PasswordPolicyLarge from '../../../../../assets/integration-builder/images/businessuser/large/pwdpolicy.svg';
import  LdapSearchLarge from '../../../../../assets/integration-builder/images/businessuser/large/ldapsearch.svg';

import  { ReactComponent as SAPFunction } from '../../../../../assets/integration-builder/images/sap/function.svg';
import  { ReactComponent as Commit } from '../../../../../assets/integration-builder/images/sap/commit.svg';
import  { ReactComponent as Rollback } from '../../../../../assets/integration-builder/images/sap/rollback.svg';
import  { ReactComponent as Starttrans } from '../../../../../assets/integration-builder/images/sap/starttrans.svg';
import  { ReactComponent as Stoptrans } from '../../../../../assets/integration-builder/images/sap/stoptrans.svg';
import  SAPFunctionLarge from '../../../../../assets/integration-builder/images/sap/large/function.svg';
import  CommitLarge from '../../../../../assets/integration-builder/images/sap/large/commit.svg';
import  RollbackLarge from '../../../../../assets/integration-builder/images/sap/large/rollback.svg';
import  StarttransLarge from '../../../../../assets/integration-builder/images/sap/large/starttrans.svg';
import  StoptransLarge from '../../../../../assets/integration-builder/images/sap/large/stoptrans.svg';

import  { ReactComponent as SFList } from '../../../../../assets/integration-builder/images/salesforce/list.svg';
import  { ReactComponent as SFCreate } from '../../../../../assets/integration-builder/images/salesforce/create.svg';
import  { ReactComponent as SFUpdate } from '../../../../../assets/integration-builder/images/salesforce/update.svg';
import  { ReactComponent as SFDelete } from '../../../../../assets/integration-builder/images/salesforce/delete.svg';
import  SFListLarge from '../../../../../assets/integration-builder/images/salesforce/large/list.svg';
import  SFCreateLarge from '../../../../../assets/integration-builder/images/salesforce/large/create.svg';
import  SFUpdateLarge from '../../../../../assets/integration-builder/images/salesforce/large/update.svg';
import SFDeleteLarge from '../../../../../assets/integration-builder/images/salesforce/large/delete.svg';

// All large icons will be similar to this.
import  { ReactComponent as Split } from '../../../../../assets/integration-builder/images/function/split.svg';
import  { ReactComponent as Length } from '../../../../../assets/integration-builder/images/function/length.svg';
import  { ReactComponent as Substr } from '../../../../../assets/integration-builder/images/function/substr.svg';
import  { ReactComponent as Escapesql } from '../../../../../assets/integration-builder/images/function/escapesql.svg';
import  { ReactComponent as Unescapesql } from '../../../../../assets/integration-builder/images/function/unescapesql.svg';
import  { ReactComponent as Currentdate } from '../../../../../assets/integration-builder/images/function/currentdate.svg';
import  { ReactComponent as Dateformat } from '../../../../../assets/integration-builder/images/function/dateformat.svg';
import  { ReactComponent as Dateconverter } from '../../../../../assets/integration-builder/images/function/dateconverter.svg';
import  { ReactComponent as Xmltotable } from '../../../../../assets/integration-builder/images/function/xmltotable.svg';
import  { ReactComponent as Encode } from '../../../../../assets/integration-builder/images/function/encode.svg';
import  { ReactComponent as Decode } from '../../../../../assets/integration-builder/images/function/decode.svg';
import  { ReactComponent as Lowercase } from '../../../../../assets/integration-builder/images/function/lowercase.svg';
import  { ReactComponent as Uppercase } from '../../../../../assets/integration-builder/images/function/uppercase.svg';
import  { ReactComponent as Tabletostr } from '../../../../../assets/integration-builder/images/function/tabletostr.svg';
import  { ReactComponent as TabletoJson } from '../../../../../assets/integration-builder/images/function/tabletojson.svg';
import  { ReactComponent as Count } from '../../../../../assets/integration-builder/images/function/count.svg';
import  { ReactComponent as Lookup } from '../../../../../assets/integration-builder/images/function/lookup.svg';
import  { ReactComponent as Digent } from '../../../../../assets/integration-builder/images/function/digent.svg';
import  SplitLarge from '../../../../../assets/integration-builder/images/function/split.svg';
import LengthLarge from '../../../../../assets/integration-builder/images/function/length.svg';
import SubstrLarge from '../../../../../assets/integration-builder/images/function/substr.svg';
import  EscapesqlLarge from '../../../../../assets/integration-builder/images/function/escapesql.svg';
import  UnescapesqlLarge from '../../../../../assets/integration-builder/images/function/unescapesql.svg';
import  CurrentdateLarge from '../../../../../assets/integration-builder/images/function/currentdate.svg';
import  DateformatLarge from '../../../../../assets/integration-builder/images/function/dateformat.svg';
import  DateconverterLarge from '../../../../../assets/integration-builder/images/function/dateconverter.svg';
import  XmltotableLarge from '../../../../../assets/integration-builder/images/function/xmltotable.svg';
import  EncodeLarge from '../../../../../assets/integration-builder/images/function/encode.svg';
import  DecodeLarge from '../../../../../assets/integration-builder/images/function/decode.svg';
import  LowercaseLarge from '../../../../../assets/integration-builder/images/function/lowercase.svg';
import  UppercaseLarge from '../../../../../assets/integration-builder/images/function/uppercase.svg';
import  TabletostrLarge from '../../../../../assets/integration-builder/images/function/tabletostr.svg';
import  TabletoJsonLarge from '../../../../../assets/integration-builder/images/function/tabletojson.svg';
import  CountLarge from '../../../../../assets/integration-builder/images/function/count.svg';
import  LookupLarge from '../../../../../assets/integration-builder/images/function/lookup.svg';
import  DigentLarge from '../../../../../assets/integration-builder/images/function/digent.svg';

import  { ReactComponent as DriveUpload } from '../../../../../assets/integration-builder/images/drive/upload.svg';
import  { ReactComponent as DriveDownload } from '../../../../../assets/integration-builder/images/drive/download.svg';
import  { ReactComponent as DriveCopy } from '../../../../../assets/integration-builder/images/drive/copy.svg';
import  { ReactComponent as DriveMove } from '../../../../../assets/integration-builder/images/drive/move.svg';
import  { ReactComponent as DriveDelete } from '../../../../../assets/integration-builder/images/drive/delete.svg';
import  { ReactComponent as DriveList } from '../../../../../assets/integration-builder/images/drive/list.svg';
import  DriveUploadLarge from '../../../../../assets/integration-builder/images/drive/large/upload.svg';
import  DriveDownloadLarge from '../../../../../assets/integration-builder/images/drive/large/download.svg';
import  DriveCopyLarge from '../../../../../assets/integration-builder/images/drive/large/copy.svg';
import  DriveMoveLarge from '../../../../../assets/integration-builder/images/drive/large/move.svg';
import  DriveDeleteLarge from '../../../../../assets/integration-builder/images/drive/large/delete.svg';
import  DriveListLarge from '../../../../../assets/integration-builder/images/drive/large/list.svg';

import  { ReactComponent as WFRestart } from '../../../../../assets/integration-builder/images/workflow/restart.svg';
import  { ReactComponent as WFStart } from '../../../../../assets/integration-builder/images/workflow/start.svg';
import  { ReactComponent as WFRetry } from '../../../../../assets/integration-builder/images/workflow/retry.svg';
import  { ReactComponent as WFAssignTo } from '../../../../../assets/integration-builder/images/workflow/assignto.svg';
import  { ReactComponent as WFRevert } from '../../../../../assets/integration-builder/images/workflow/revert.svg';
import  { ReactComponent as WFContinue } from '../../../../../assets/integration-builder/images/workflow/continue.svg';
import  { ReactComponent as WFEnd } from '../../../../../assets/integration-builder/images/workflow/end.svg';
import {ReactComponent as WFArchive} from '../../../../../assets/integration-builder/images/workflow/archive.svg';
import  WFRestartLarge from '../../../../../assets/integration-builder/images/workflow/large/restart.svg';
import  WFstartLarge from '../../../../../assets/integration-builder/images/workflow/large/start.svg';
import  WFRetryLarge from '../../../../../assets/integration-builder/images/workflow/large/retry.svg';
import  WFRevertLarge from '../../../../../assets/integration-builder/images/workflow/large/revert.svg';
import  WFAssignToLarge from '../../../../../assets/integration-builder/images/workflow/large/assignto.svg';
import  WFContinueLarge from '../../../../../assets/integration-builder/images/workflow/large/continue.svg';
import  WFEndLarge from '../../../../../assets/integration-builder/images/workflow/large/end.svg';
import  WFArchiveLarge from '../../../../../assets/integration-builder/images/workflow/large/archive.svg';

import  { ReactComponent as Erdbselect } from '../../../../../assets/integration-builder/images/freedomdb/erdbselect.svg';
import  { ReactComponent as Erdbcreate } from '../../../../../assets/integration-builder/images/freedomdb/erdbcreate.svg';
import  { ReactComponent as Erdbupdate } from '../../../../../assets/integration-builder/images/freedomdb/erdbupdate.svg';
import  { ReactComponent as Erdbdelete } from '../../../../../assets/integration-builder/images/freedomdb/erdbdelete.svg';
import  { ReactComponent as ErdbPrepared } from '../../../../../assets/integration-builder/images/freedomdb/erdbprepared.svg';
import  { ReactComponent as ErdbQuery } from '../../../../../assets/integration-builder/images/freedomdb/erdbquery.svg';
import  ErdbselectLarge from '../../../../../assets/integration-builder/images/freedomdb/large/erdbselect.svg';
import  ErdbcreateLarge from '../../../../../assets/integration-builder/images/freedomdb/large/erdbcreate.svg';
import  ErdbupdateLarge from '../../../../../assets/integration-builder/images/freedomdb/large/erdbupdate.svg';
import  ErdbdeleteLarge from '../../../../../assets/integration-builder/images/freedomdb/large/erdbdelete.svg';
import  ErdbPreparedLarge from '../../../../../assets/integration-builder/images/freedomdb/large/erdbprepared.svg';

import  { ReactComponent as DBAdapterSelect } from '../../../../../assets/integration-builder/images/dbadapter/select.svg';
import  { ReactComponent as DBAdapterCreate } from '../../../../../assets/integration-builder/images/dbadapter/create.svg';
import  { ReactComponent as DBAdapterUpdate } from '../../../../../assets/integration-builder/images/dbadapter/update.svg';
import  { ReactComponent as DBAdapterDelete } from '../../../../../assets/integration-builder/images/dbadapter/delete.svg';
import  { ReactComponent as DBAdapterPrepared } from '../../../../../assets/integration-builder/images/dbadapter/prepared.svg';
import  DBAdapterSelectLarge from '../../../../../assets/integration-builder/images/dbadapter/large/select.svg';
import  DBAdapterCreateLarge from '../../../../../assets/integration-builder/images/dbadapter/large/create.svg';
import  DBAdapterUpdateLarge from '../../../../../assets/integration-builder/images/dbadapter/large/update.svg';
import  DBAdapterDeleteLarge from '../../../../../assets/integration-builder/images/dbadapter/large/delete.svg';
import  DBAdapterPreparedLarge from '../../../../../assets/integration-builder/images/dbadapter/large/prepared.svg';

import  { ReactComponent as PdfGenerate } from '../../../../../assets/integration-builder/images/utilities/pdfgenerate.svg';
import  { ReactComponent as Custom } from '../../../../../assets/integration-builder/images/custom/custom.svg';
import  CustomLarge from '../../../../../assets/integration-builder/images/custom/large/custom.svg';

import  { ReactComponent as FmAuthentication } from '../../../../../assets/integration-builder/images/authentication/fmauthentication.svg';
import  { ReactComponent as OAuthAuthentication } from '../../../../../assets/integration-builder/images/authentication/oauthauthentication.svg';
import  { ReactComponent as SamlAuthentication } from '../../../../../assets/integration-builder/images/authentication/samlauthentication.svg';
import  { ReactComponent as SapAuthentication } from '../../../../../assets/integration-builder/images/authentication/sapauthentication.svg';

import  { ReactComponent as ODataList } from '../../../../../assets/integration-builder/images/odata/ODataList.svg';
import  { ReactComponent as ODataCreate } from '../../../../../assets/integration-builder/images/odata/ODataCreate.svg';
import  { ReactComponent as ODataUpdate } from '../../../../../assets/integration-builder/images/odata/ODataUpdate.svg';
import  { ReactComponent as ODataDelete } from '../../../../../assets/integration-builder/images/odata/ODataDelete.svg';

import  { ReactComponent as ConflictQueue } from '../../../../../assets/integration-builder/images/conflict/conflictQueue.svg';



const IBBlocks = {
  edgereadyruntime: {
    runondevice: {
      small: <Runondevice />,
      large: RunondeviceLarge,
    },
    runoncloud: {
      small: <Runoncloud />,
      large: RunoncloudLarge,
    },
    runonpremise: {
      small: <Runonpremise />,
      large: RunonpremiseLarge,
    },
   
  },
  logic: {
    repeater: {
      small: <Repeater />,
      large: RepeaterLarge,
    },
    loop: {
      small: <Loopuntil />,
      large: LoopuntilLarge,
    },
    if: {
      small: <If />,
      large: IfLarge,
    },
    ifelse: {
      small: <Ifelse />,
      large: IfelseLarge,
    },
    conditional: {
      small: <Comparison />,
      large: ComparisionLarge,
    },
    logical: {
      small: <And />,
      large: AndLarge,
    },
    break: {
      small: <Break />,
      large: BreakLarge,
    },
    terminate: {
      small: <Terminate />,
      large: TerminateLarge,
    },
    addrow: {
      small: <Addrow />,
      large: AddrowLarge,
    },
  },
  utilities: {
      sort: {
        small:<Sort />,
        large: SortLarge,
    },
      download: {
        small:<Downloadfile />,
        large: DownloadfileLarge,
    },
      generatefile: {
        small: <Generatefile />,
        large: GeneratefileLarge,
    },
      parsecsv: {
        small: <Parsecsv />,
        large: ParsecsvLarge,
    },
    comment: {
      small: <Comment />,
      large: CommentLarge,
    },
    pdfgenerate: {
      small: <PdfGenerate />,
      large: AddrowLarge,
    },
    generateqr:{
      small: <GenerateQR />,
      large: GenerateQRLarge,
    },
  },
  notifications: {
      mail: {
          small: <Mailto />,
      large: MailtoLarge,
    },
      push: {
          small: <Pushuser />,
      large: PushuserLarge,
    },
      devicesync: {
          small: <Devicesyncuser />,
      large: DevicesyncuserLarge,
    },
  },
  ftp: {
    ftp: {
        small: <Ftp />,
      large: FtpLarge,
  },
  },
  bos: {
      bosservice: {
          small: <Bos />,
      large: BosLarge,
    },
  },
  webservice: {
      soap: {
          small: <Webservice />,
      large: WebserviceLarge,
    },
      rest: {
          small: <Restservice />,
      large: RestserviceLarge,
    },
  },
  sharepoint: {
    rest:{
    //sharepoint: {
      small: <SPRestservice />,
      large: SPRestserviceLarge,
    },
  },
  ariba: {
    rest: {
    //ariba: {
      small: <SPRestservice />,
      large: SPRestserviceLarge,
    },
  },
  kronos: {
    rest: {
    //kronos: {
      small: <SPRestservice />,
      large: SPRestserviceLarge,
    },
  },
  businessuser: {
    userlist:{
      small: <Userlist />,
      large: UserListLarge,
    },
    userdetails:{
      small: <Userdetails />,
      large: UserDetailLarge,
    },
    userproperty:{
      small: <UserProperty />,
      large: UserPropertyLarge,
    },
    createuser: {
      small: <Createuser />,
      large: CreateuserLarge,
    },
    updateuser: {
      small: <Updateuser />,
      large: UpdateuserLarge,
    },
    rolelist: {
      small: <RoleList />,
      large: RoleListLarge,
    },    
    assigngroup: {
      small: <Addrole />,
      large: AddroleLarge,
    },
    removegroup: {
      small: <Removerole />,
      large: AddroleLarge,
    },
	  resetpassword: {
      small: <ResetPassword />,
      large: ResetPasswordLarge,
    },
    passwordpolicy: {
      small: <PassswordPolicy />,
      large: PasswordPolicyLarge,
    },
    ldapsearch:{
      small:<LdapSearch/>,
      large:LdapSearchLarge,
    }
  },
  sap: {
      sap: {
          small: <SAPFunction />,
      large: SAPFunctionLarge,
    },
    sapcommit: {
        small:<Commit />,
      large: CommitLarge,
    },
    saprollback: {
        small:<Rollback />,
      large: RollbackLarge,
    },
    sapstarttransaction: {
        small:<Starttrans />,
      large: StarttransLarge,
    },
    sapstoptransaction: {
        small:<Stoptrans />,
      large: StoptransLarge,
    },
  },
  salesforce: {
    list: {
        small:<SFList />,
      large: SFListLarge,
    },
    create: {
        small:<SFCreate />,
      large: SFCreateLarge,
    },
    update: {
        small:<SFUpdate />,
      large: SFUpdateLarge,
    },
    delete: {
        small:<SFDelete />,
      large: SFDeleteLarge,
    },
  },
  operations: {
    assignment:{
        small: <LogicSet />,
      large: LogicSetLarge,
    },
    math: {
        small:<Math />,
      large: MathLarge,
    },
    split: {
        small:<Split />,
      large: SplitLarge,
    },
    length: {
        small:<Length />,
      large: LengthLarge,
    },
    substr: {
        small:<Substr />,
      large: SubstrLarge,
    },
    escapesql: {
        small:<Escapesql />,
      large: EscapesqlLarge,
    },
    unescapesql: {
        small:<Unescapesql />,
      large: UnescapesqlLarge,
    },
    currentdate: {
        small:<Currentdate />,
      large: CurrentdateLarge,
    },
    dateformat: {
        small:<Dateformat />,
      large: DateformatLarge,
    },
    dateconverter: {
      small: <Dateconverter />,
      large: DateconverterLarge,
    },
    xmltotable: {
      small: <Xmltotable />,
      large: XmltotableLarge,
    },
    encode: {
        small:<Encode />,
      large: EncodeLarge,
    },
    decode: {
        small:<Decode />,
      large: DecodeLarge,
    },
    lowercase: {
        small:<Lowercase />,
      large: LowercaseLarge,
    },
    uppercase: {
        small:<Uppercase />,
      large: UppercaseLarge,
    },
    tabletostring:{
        small: <Tabletostr />,
      large: TabletostrLarge,
    },
    tabletojson:{
      small: <TabletoJson />,
      large: TabletoJsonLarge,
  },
    lookup: {
        small:<Lookup />,
      large: LookupLarge,
    },
    count: {
      small:<Count />,
      large: CountLarge,
    },
    generatehashcode: {
      small:<Digent />,
      large: DigentLarge,
    }
  },
  edgereadydrive: {
    upload: {
        small:<DriveUpload />,
      large: DriveUploadLarge,
    },
    download: {
        small:<DriveDownload />,
      large: DriveDownloadLarge,
    },
    copy: {
        small:<DriveCopy />,
      large: DriveCopyLarge,
    },
    move: {
        small:<DriveMove />,
      large: DriveMoveLarge,
    },
    delete: {
        small:<DriveDelete />,
      large: DriveDeleteLarge,
    },
    list:{
        small: <DriveList />,
      large: DriveListLarge,
    },
  },
  edgereadydatabase: {
    select: {
        small:<Erdbselect />,
      large: ErdbselectLarge,
    },
    create: {
        small:<Erdbcreate />,
      large: ErdbcreateLarge,
    },
    update: {
        small:<Erdbupdate />,
      large: ErdbupdateLarge,
    },
    delete: {
        small:<Erdbdelete />,
      large: ErdbdeleteLarge,
    },
    prepared: {
      small:<ErdbPrepared />,
      large: ErdbPreparedLarge,
    },
    query: {
      small:<ErdbQuery />,
      large: ErdbPreparedLarge,
    }
  },
  externaldatabase: {
    select: {
        small:<DBAdapterSelect />,
      large: DBAdapterSelectLarge,
    },
    create: {
        small:<DBAdapterCreate />,
      large: DBAdapterCreateLarge,
    },
    update: {
        small:<DBAdapterUpdate />,
      large: DBAdapterUpdateLarge,
    },
    delete: {
        small:<DBAdapterDelete />,
      large: DBAdapterDeleteLarge,
    },
    prepared: {
      small:<DBAdapterPrepared />,
      large: DBAdapterPreparedLarge,
    },
    query: {
      small:<ErdbQuery />,
      large: DBAdapterPreparedLarge,
    },
    query: {
      small:<ErdbQuery />,
      large: DBAdapterPreparedLarge,
    },
  },
  // pdf: {
  //   generatepdf: {
  //     small: <Custom />,
  //     large: CustomLarge,
  //   },
  // },
  snowflakedatabase: {
    select: {
      small: <DBAdapterSelect />,
      large: DBAdapterSelectLarge,
    },
    create: {
      small: <DBAdapterCreate />,
      large: DBAdapterCreateLarge,
    },
    update: {
      small: <DBAdapterUpdate />,
      large: DBAdapterUpdateLarge,
    },
    delete: {
      small: <DBAdapterDelete />,
      large: DBAdapterDeleteLarge,
    },
    prepared: {
      small: <DBAdapterPrepared />,
      large: DBAdapterPreparedLarge,
    },
  },
  authentication: {
    fmauthentication: {
      small: <FmAuthentication />,
      large: SPRestserviceLarge,
    },
    oauthauthentication: {
      small: <OAuthAuthentication />,
      large: SPRestserviceLarge,
    },
    samlauthentication: {
      small: <SamlAuthentication />,
      large: SPRestserviceLarge,
    },
    sapauthentication: {
      small: <SapAuthentication />,
      large: SPRestserviceLarge,
    },
  },
  odata: {
    odatalist: {
      small: <ODataList />,
      large: SPRestserviceLarge,
    },
    odatacreate: {
      small: <ODataCreate />,
      large: SPRestserviceLarge,
    },
    odataupdate: {
      small: <ODataUpdate />,
      large: SPRestserviceLarge,
    },
    odatadelete: {
      small: <ODataDelete />,
      large: SPRestserviceLarge,
    },
  },
  conflict: {
    conflictqueue: {
      small: <ConflictQueue />,
      large: SPRestserviceLarge,
    },
  },workflow: {
    start: {
      small:<WFStart />,
      large: WFstartLarge,
    },
    restart: {
      small:<WFRestart />,
      large: WFRestartLarge,
    },retry: {
      small:<WFRetry />,
      large: WFRetryLarge,
    },end: {
      small:<WFEnd />,
      large: WFEndLarge,
    },continue: {
      small:<WFContinue />,
      large: WFContinueLarge,
    },revert: {
      small:<WFRevert />,
      large: WFRevertLarge,
    },assign: {
      small:<WFAssignTo />,
      large: WFAssignToLarge,
    },archive:{
      small:<WFArchive/>,
      large: WFArchiveLarge
    }
  }
};

export default IBBlocks;
